import { useState, useEffect } from 'react';
import { getProgressionsOfLeads30Days } from '@shared/api/all/stats';
import { statusesOfLeads } from '@shared/const/statuses';

import { Line } from '@ant-design/plots';

const ServicesGraphsLeads = () => {
   const [data, setData] = useState([]);
   const [mapLabelColor, setMapLabelColor] = useState({});

   useEffect(() => {
      fetchData();

      const map = {};
      const mapLabelColor = Object.values(statusesOfLeads).reduce(
         (acc, { label, color }) => {
            acc[label] = color;
            return acc;
         },
         {}
      );

      setMapLabelColor(mapLabelColor);
   }, []);

   const fetchData = () => {
      getProgressionsOfLeads30Days().then((res) => {
         let apiArr = res?.json?.data || {};

         const result = [];

         Object.entries(apiArr).forEach(([date, records]) => {
            const counts = {};

            records.forEach(({ statusObj }) => {
               const status = statusObj.status;
               counts[status] = (counts[status] || 0) + 1;
            });

            Object.entries(counts).forEach(([status, value]) => {
               result.push({
                  date: date.slice(5),
                  value,
                  status: status,
                  category: statusesOfLeads[status].label
               });
            });
         });

         setData(result);
      });
   };

   const config = {
      data,
      xField: 'date',
      yField: 'value',
      seriesField: 'category',
      point: {
         size: 5,
         shape: 'circle'
      },
      color: ({ category }) => mapLabelColor[category] || '#999',
      smooth: true
   };

   return <Line {...config} />;
};

export default ServicesGraphsLeads;
