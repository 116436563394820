import { useState, useEffect } from 'react';
import { getProgressionsOfBuyedLeadsMonth } from '@shared/api/all/stats';
import { getMonthName } from '@shared/utils/default';

import { Line } from '@ant-design/plots';

const monthOrder = {
   Январь: 1,
   Февраль: 2,
   Март: 3,
   Апрель: 4,
   Май: 5,
   Июнь: 6,
   Июль: 7,
   Август: 8,
   Сентябрь: 9,
   Октябрь: 10,
   Ноябрь: 11,
   Декабрь: 12
};

const ServicesGraphsBuyedLeadsCount = () => {
   const [data, setData] = useState([]);

   const fetchData = () => {
      getProgressionsOfBuyedLeadsMonth().then((res) => {
         let apiArr = res?.json?.data || [];

         apiArr = Object.keys(apiArr).map((key) => {
            return {
               month: getMonthName(key),
               totalChange: apiArr[key],
               category: 'Куплено заявок'
            };
         });
         setData(apiArr);
      });
   };

   useEffect(() => {
      fetchData();
   }, []);

   const config = {
      data,
      xField: 'month',
      yField: 'totalChange',
      seriesField: 'category',
      point: {
         size: 5,
         shape: 'circle'
      },
      color: 'green',
      smooth: true
   };

   return <Line {...config} />;
};

export default ServicesGraphsBuyedLeadsCount;
