import { useState, useEffect } from 'react';
import { getProgressionsOfLeadsFinishedConfirms30Days } from '@shared/api/all/stats';
import { statusesOfLeads } from '@shared/const/statuses';

import { Line } from '@ant-design/plots';

const getConfirmCategory = (obj) => {
   if (obj.statusSeller === 'confirmed' && obj.statusDriver === 'confirmed') {
      return 'Вывоз состоялся';
   }

   if (obj.statusSeller === 'confirmed' && obj.statusDriver === 'notconfirmed') {
      return 'Вывоз состоялся';
   }

   if (obj.statusSeller === 'confirmed' && obj.statusDriver === null) {
      return 'Вывоз состоялся';
   }

   return 'Вывоз не состоялся';
};

const ServicesGraphsLeadsConfirms = () => {
   const [data, setData] = useState([]);

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = () => {
      getProgressionsOfLeadsFinishedConfirms30Days().then((res) => {
         let apiArr = res?.json?.data || {};
         const result = [];

         Object.entries(apiArr).forEach(([date, records]) => {
            const counts = {};

            records.forEach(({ leadconfirm }) => {
               const category = getConfirmCategory(leadconfirm);
               counts[category] = (counts[category] || 0) + 1;
            });

            Object.entries(counts).forEach(([category, value]) => {
               result.push({
                  date: date.slice(5),
                  value,
                  category: category
               });
            });
         });

         setData(result);
      });
   };

   const colorMap = {
      'Вывоз состоялся': 'green',
      'Вывоз не состоялся': 'red'
   };

   const config = {
      data,
      xField: 'date',
      yField: 'value',
      seriesField: 'category',
      point: {
         size: 5,
         shape: 'circle'
      },
      color: ({ category }) => colorMap[category] || '#999',
      smooth: true
   };

   return <Line {...config} />;
};

export default ServicesGraphsLeadsConfirms;
